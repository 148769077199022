import axios from "axios";
import { clearSession, getSession, storageKey } from "../lib/session";

const NODE_ENV = process.env.NODE_ENV;
const baseURL =
  NODE_ENV === "development"
    ? "https://socket-api.clickmate.co.kr"
    : "https://socket-api.clickmate.link";

console.log("env:", NODE_ENV);

const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use(
  function (request) {
    return request;
  },
  function (err) {
    console.log(err);
    throw err;
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (err) {
    if (err.response && err.response.status === 401) {
      alert("세션이 만료되어 로그인 페이지로 이동합니다.");
      localStorage.removeItem(storageKey.accessToken);
      if (window) window.location.href = "/";
    }

    throw err;
  }
);

export const setSocketHeader = () => {
  const session = getSession();

  if (!session) {
    clearSession();
    return;
  }

  instance.defaults.headers.common["Authorization"] = `Bearer ${session}`;
};

export default instance;
